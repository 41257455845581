import React, { useState } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { trans } from 'sharedKernel/i18n'
import { EVALUATION_STATUS, SOLUTION_STATUS } from 'sharedKernel/constants'
import { HubTable } from 'components/HubDefaultTable'
import { IEvaluation, ISolution } from '../contracts'
import { EvaluationLabel, CenterContent, CellLink, IdeaTitle } from './style'
import { filterByTabValue, filterValueMatchAnyField, sortRows } from './behavior'
import { NoContent } from 'components/NoContent'
import NoContentImg from 'assets/images/defaults/no_challenges.png'
import { ApproveRejectButtons } from 'components/ApproveRejectButtons'
import { TitleContainer } from 'components/HubDefaultTable/style'
import { showModal } from 'components/popup'
import { EvaluationModal } from '../evaluationModal'
import { useNavigate, useParams } from 'react-router'
import { AnimatedSortButtons } from 'components/HubDefaultTable/OrderByButton'

export interface HubCompanyChallengeSolutionsTableProps {
  solutions: Solutions
  tabStatus: string
  filterValue: string
  approveSolution: (solutionId: string) => void
  archiveSolution: (solutionId: string) => void
}

interface Solutions {
  rows: any[]
}

export interface SortTable {
  finalScoreAsc: boolean
  statusAsc: boolean
  solutionTitleAsc: boolean
}

export interface OrderClick {
  finalScoreClick: boolean
  statusClick: boolean
  solutionTitleClick: boolean
}

export const EVALUATORS_SHOWN_LIST = 5

export const NO_CONTENT_CHALLENGE_SOLUTIONS_MESSAGE = 'Nenhuma solução encontrada para este evento'

export const HubCompanyChallengeSolutionsTable = ({ solutions, tabStatus, filterValue, approveSolution, archiveSolution }: HubCompanyChallengeSolutionsTableProps): JSX.Element => {
  const { challengeId } = useParams()
  const navigate = useNavigate()
  const [sortTable, setSortTable] = useState<SortTable>({ finalScoreAsc: false, statusAsc: false, solutionTitleAsc: false })
  const [orderClick, setOrderClick] = useState<OrderClick>({ finalScoreClick: false, statusClick: false, solutionTitleClick: true })
  const labels = {
    name: trans('Solução'),
    firstEvaluator: `${trans('Avaliador')} 1`,
    SecondEvaluator: `${trans('Avaliador')} 2`,
    thirdEvaluator: `${trans('Avaliador')} 3`,
    fourthEvaluator: `${trans('Avaliador')} 4`,
    fifthEvaluator: `${trans('Avaliador')} 5`,
    finalScore: trans('Nota final'),
    standardDeviation: trans('Desvio padrão'),
    status: trans('Status'),
    actions: trans('Ações'),
    noContent: trans(NO_CONTENT_CHALLENGE_SOLUTIONS_MESSAGE),
    canceledEvaluationTooltip: trans('Cancelada'),
    emptyEvaluationTooltip: trans('Sem avaliação'),
    evaluator: trans('Avaliação'),
    incompleteEvaluation: trans('Avaliação não finalizada'),
    incompleteEvaluations: trans('Avaliações não finalizadas'),
    notLunched: trans('Esta solução ainda não foi publicada.'),
    notEvaluated: trans('Esta solução ainda não foi avalaida.')
  }

  const columnMasks = {
    pendingEvaluation: trans('Não iniciado'),
    notAcceptedEvaluation: trans('Não aceito'),
    empty: '-'
  }

  const solutionStatusLabel = {
    [`${SOLUTION_STATUS.APPROVED}`]: {
      color: 'var(--green)',
      tooltip: trans('Aprovada')
    },
    [`${SOLUTION_STATUS.DISAPPROVED}`]: {
      color: 'var(--red)',
      tooltip: trans('Reprovada')
    },
    [`${SOLUTION_STATUS.CREATED}`]: {
      color: 'var(--grey-light)',
      tooltip: trans('Criada')
    },
    [`${SOLUTION_STATUS.LAUNCHED}`]: {
      color: 'var(--yellow)',
      tooltip: trans('Lançada')
    },
    [`${SOLUTION_STATUS.CANCELED}`]: {
      color: 'var(--grey-light)',
      tooltip: trans('Cancelada')
    }
  }

  const tableHeader = (): any[] => {
    return [
      getCenteredContent(buildHeaderSolutionTitle()),
      getCenteredContent(labels.firstEvaluator),
      getCenteredContent(labels.SecondEvaluator),
      getCenteredContent(labels.thirdEvaluator),
      getCenteredContent(labels.fourthEvaluator),
      getCenteredContent(labels.fifthEvaluator),
      getCenteredContent(buildHeaderFinalScore()),
      getCenteredContent(labels.standardDeviation),
      getCenteredContent(buildHeaderStatus()),
      getCenteredContent(labels.actions)
    ]
  }
  const buildHeaderSolutionTitle = (): JSX.Element => {
    return (
      <>
        {labels.name}
        <div className='ml-1'>
          <AnimatedSortButtons orderByAsc={sortTable.solutionTitleAsc}
            onClick={() => {
              setSortTable({ ...sortTable, solutionTitleAsc: !sortTable.solutionTitleAsc })
              setOrderClick({ ...orderClick, solutionTitleClick: true, finalScoreClick: false, statusClick: false })
            }} />
        </div>
      </>
    )
  }

  const buildHeaderFinalScore = (): JSX.Element => {
    return (
      <>
        {labels.finalScore}
        <div className='ml-1'>
          <AnimatedSortButtons orderByAsc={sortTable.finalScoreAsc}
            onClick={() => {
              setSortTable({ ...sortTable, finalScoreAsc: !sortTable.finalScoreAsc })
              setOrderClick({ ...orderClick, finalScoreClick: true, solutionTitleClick: false, statusClick: false })
            }}
          />
        </div>
      </>
    )
  }
  const buildHeaderStatus = (): JSX.Element => {
    return (
      <>
        {labels.status}
        <div className='ml-1'>
          <AnimatedSortButtons orderByAsc={sortTable.statusAsc}
            onClick={() => {
              setSortTable({ ...sortTable, statusAsc: !sortTable.statusAsc })
              setOrderClick({ ...orderClick, statusClick: true, finalScoreClick: false, solutionTitleClick: false })
            }}
          />
        </div>
      </>
    )
  }

  const buildSolutionRows = (): any[] => {
    filterRows()
    return solutions.rows
      .filter((solution) => { return filterByTabValue(solution, tabStatus) })
      .filter((solution) => { return filterValueMatchAnyField(solution, filterValue, columnMasks) })
      .map((solution: ISolution, index) => {
        return buildSolutionColumns(solution)
      })
  }

  const filterRows = (): void => {
    let newRows = sortRows(solutions.rows, sortTable.finalScoreAsc, sortTable.solutionTitleAsc, orderClick.finalScoreClick, orderClick.solutionTitleClick)
    const launchedIdeas = newRows.filter((solution) => solution.status.name === SOLUTION_STATUS.LAUNCHED)
    const approvedIdeas = newRows.filter((solution) => solution.status.name === SOLUTION_STATUS.APPROVED)
    const createdIdeas = newRows.filter((solution) => solution.status.name === SOLUTION_STATUS.CREATED)
    const disapprovedIdeas = newRows.filter((solution) => solution.status.name === SOLUTION_STATUS.DISAPPROVED)
    if (orderClick.statusClick) {
      if (sortTable.statusAsc) {
        newRows = [...disapprovedIdeas, ...createdIdeas, ...launchedIdeas, ...approvedIdeas]
      } else {
        newRows = [...approvedIdeas, ...launchedIdeas, ...createdIdeas, ...disapprovedIdeas]
      }
    }
    solutions.rows = [...newRows]
  }

  const buildSolutionColumns = (solution: ISolution): any[] => {
    const solutionColumns: any = []

    solutionColumns.push(getSolutionTitle(solution))

    for (let index = 0; index < EVALUATORS_SHOWN_LIST; index++) {
      if (index < solution.evaluation.evaluations.length) {
        const modalTitle = `${labels.evaluator} - ${solution.evaluation.evaluations[index].evaluator.name}`
        let evaluationContent = getEvaluationContent(solution.evaluation.evaluations[index])
        evaluationContent = getCellLink(evaluationContent, modalTitle, solution.id, solution.evaluation.evaluations[index].evaluator.id)
        evaluationContent = getCenteredContent(evaluationContent)
        solutionColumns.push(evaluationContent)
        continue
      }

      solutionColumns.push(getCenteredContent(getEvaluationEmptyLabel(labels.emptyEvaluationTooltip)))
    }

    solutionColumns.push(getCenteredContent(solution.evaluation.averageScore))
    solutionColumns.push(getCenteredContent(getSolutionStandartDeviation(solution)))
    solutionColumns.push(getCenteredContent(getSolutionStatus(solution)))
    solutionColumns.push(getSolutionActions(solution))

    return solutionColumns
  }

  const getCellLink = (content: any, title: string, solutionId: string, evaluatorId: string): any => {
    return (
      <CellLink
        onClick={() => {
          showModal('single', {
            title: title,
            message: <EvaluationModal challengeId={challengeId} solutionId={solutionId} evaluatorId={evaluatorId} />
          })
        }}
      >
        {content}
      </CellLink>
    )
  }
  const getSolutionTitle = (solution: ISolution): JSX.Element => {
    return (
      <TitleContainer className='d-flex justify-content-start'>
        <CellLink
          onClick={
            () => navigate(`/hub/company/challenge/${challengeId}/solutions/${solution.id}/subscription`)
          }
        >
          <FontAwesomeIcon icon={['fas', 'lightbulb']} color='var(--primary)' size='lg' className='mr-2' />
          <IdeaTitle>
            {solution.title}
          </IdeaTitle>
        </CellLink>
      </TitleContainer>
    )
  }
  const getEvaluationContent = (evaluation: IEvaluation): JSX.Element | number | string => {
    const evaluationStatusContentRule = {
      [`${EVALUATION_STATUS.CREATED}`]: getIncompleteEvaluation(evaluation.points),
      [`${EVALUATION_STATUS.PENDING}`]: getEvaluationLabel(columnMasks.pendingEvaluation, 'yellow'),
      [`${EVALUATION_STATUS.EVALUATED}`]: evaluation.points,
      [`${EVALUATION_STATUS.NOT_ACCEPTED}`]: getEvaluationLabel(columnMasks.notAcceptedEvaluation, 'red'),
      [`${EVALUATION_STATUS.CANCELED}`]: getEvaluationEmptyLabel(labels.canceledEvaluationTooltip)
    }

    return evaluationStatusContentRule[evaluation.status]
  }

  const getIncompleteEvaluation = (text: number): JSX.Element => {
    return (
      <>
        <OverlayTrigger overlay={<Tooltip>{labels.incompleteEvaluation}</Tooltip>}>
          <span>{text}<span style={{ color: 'red' }}>*</span></span>
        </OverlayTrigger>
      </>
    )
  }

  const getEvaluationLabel = (text: string, labelColor: string): JSX.Element => {
    return <EvaluationLabel color={labelColor}>{text}</EvaluationLabel>
  }

  const getEvaluationEmptyLabel = (text: string): JSX.Element => {
    return (
      <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>}>
        <div>{columnMasks.empty}</div>
      </OverlayTrigger>
    )
  }

  const getSolutionStandartDeviation = (solution: ISolution): JSX.Element => {
    return (
      <>
        {solution.evaluation.standardDeviation}
      </>
    )
  }

  const getSolutionStatus = (solution: ISolution): JSX.Element => {
    return (
      <div className='d-flex flex-column gap-1 flex-sm-row justify-content-center align-content-center text-center'>
        <OverlayTrigger overlay={<Tooltip>{solutionStatusLabel[solution.status.name]?.tooltip}</Tooltip>}>
          <div>
            <FontAwesomeIcon
              className="pr-2"
              color={solutionStatusLabel[solution.status.name]?.color}
              icon={['fas', 'circle']}
            />
          </div>
        </OverlayTrigger>
      </div>
    )
  }

  const getSolutionActions = (solution: ISolution): JSX.Element => {
    if (solution.status.name !== SOLUTION_STATUS.LAUNCHED && solution.status.name !== SOLUTION_STATUS.CREATED) {
      return <></>
    }
    const getTippyMessage = (): string => {
      if (solution.status.name !== SOLUTION_STATUS.LAUNCHED) {
        return labels.notLunched
      } else if (solution.evaluation.averageScore === 0) {
        return labels.notEvaluated
      }
      return ''
    }

    return (
      <CenterContent>
        <ApproveRejectButtons
          positiveAction={() => approveSolution(solution.id)}
          negativeAction={() => archiveSolution(solution.id)}
          disabled={solution.evaluation.averageScore === 0 || solution.status.name === SOLUTION_STATUS.CREATED}
          tipyContent={getTippyMessage()}
        />
      </CenterContent>
    )
  }

  const getCenteredContent = (content: any): any => {
    return (
      <CenterContent>
        {content}
      </CenterContent>
    )
  }
  const getTableCaption = (): JSX.Element => {
    return (
      <span><span style={{ color: 'red' }}>*</span> {labels.incompleteEvaluations}</span>
    )
  }

  return (
    <>
      {
        solutions === null || solutions === undefined || solutions.rows.length === 0
          ? <NoContent text={labels.noContent} image={NoContentImg} />
          : <HubTable
            header={tableHeader()}
            rows={buildSolutionRows()}
            caption={getTableCaption()}
          />
      }
    </>
  )
}
