import { FULL_RESET_CHALLENGE, RESET_STEPS, UPDATE_STEP } from './actions'
import { ApplicationFormSectionFields, EvaluationSectionStep, IHubChallengeBasicFormState } from './contracts'

export const INITIAL_EVALUATIONFORM_STEPS: EvaluationSectionStep[] =
  [
    {
      id: undefined,
      name: '',
      namePlaceholder: 'Ex: "Avaliação"',
      order: 1,
      isDeleted: false,
      sections: [
        {
          id: undefined,
          name: '',
          namePlaceholder: 'Ex: "Critérios de Avaliação"',
          order: 1,
          fields: [],
          isDeleted: false
        }
      ]
    }
  ]

const INITIAL_APPLICATIONFORM_SECTIONS: ApplicationFormSectionFields[] =
  [
    {
      id: undefined,
      name: '',
      namePlaceholder: 'Digite o nome da seção 1',
      order: 1,
      isDeleted: false,
      fields: [
        {
          id: undefined,
          type: 'text',
          name: '',
          namePlaceholder: 'Ex: "Faça um Tweet Pitch"',
          description: '',
          descriptionPlaceholder: 'Ex: "Ou uma frase para ganhar a atenção do interlocutor em apenas 5 ou 10 segundos"',
          order: 1,
          isRemovable: true,
          isRequired: true,
          isDeleted: false,
          options: [],
          size: 15000,
          weight: 3
        },
        {
          id: undefined,
          type: 'text',
          name: '',
          namePlaceholder: 'Ex: "Como sua ideia pode nos ajudar?"',
          description: '',
          descriptionPlaceholder: 'Ex: "Descreva como sua ideia pode solucionar esse evento"',
          order: 2,
          isRemovable: true,
          isRequired: true,
          isDeleted: false,
          options: [],
          size: 30000
        }
      ]
    }
  ]

export const CHALLENGE_BASIC_FORM_STATE: IHubChallengeBasicFormState = {
  id: undefined,
  isLaunched: false,
  isActive: false,
  isVisible: false,
  config: {
    title: '',
    niches: [],
    type: { isPrivate: false },
    restrictions: { isInternal: false },
    step: {
      launchDate: null,
      applicationStartDate: null,
      finalSelectionDate: null,
      evaluationStartDate: null,
      closingDate: null,
      closeStepsAutomatically: true
    },
    limitOfIdeas: 1,
    files: []
  },
  style: {
    shortDescription: '',
    longDescription: ''
  },
  applicationForm: {
    id: undefined,
    name: '',
    sections: INITIAL_APPLICATIONFORM_SECTIONS
  },
  evaluation: {
    id: undefined,
    rounds: 1,
    likertScale: 5,
    steps: INITIAL_EVALUATIONFORM_STEPS
  }
}
export interface IHubChallengeBasicFormStepConfigStateAction {
  type: string
  payload: IHubChallengeBasicFormState
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const HubChallengeBasicFormReducer = (
  state: IHubChallengeBasicFormState = CHALLENGE_BASIC_FORM_STATE,
  action: IHubChallengeBasicFormStepConfigStateAction
): any => {
  switch (action.type) {
    case UPDATE_STEP:
      return {
        ...state,
        id: action.payload.id,
        isLaunched: action.payload.isLaunched,
        isActive: action.payload.isActive,
        isVisible: action.payload.isVisible,
        config: action.payload.config,
        style: action.payload.style,
        applicationForm: action.payload.applicationForm,
        evaluation: action.payload.evaluation
      }

    case RESET_STEPS:
      return {
        id: undefined,
        config: {
          title: '',
          niches: [],
          type: { isPrivate: false },
          restrictions: { isInternal: false },
          step: {
            launchDate: null,
            applicationStartDate: null,
            finalSelectionDate: null,
            evaluationStartDate: null,
            closingDate: null,
            closeStepsAutomatically: true
          },
          files: []
        },
        style: {
          shortDescription: '',
          longDescription: ''
        },
        applicationForm: {
          id: undefined,
          sections: INITIAL_APPLICATIONFORM_SECTIONS
        },
        evaluation: {
          id: undefined,
          rounds: 1,
          likertScale: 5,
          steps: INITIAL_EVALUATIONFORM_STEPS
        }
      }

    case FULL_RESET_CHALLENGE:
      return {
        id: undefined,
        config: {
          title: '',
          niches: [],
          type: { isPrivate: false },
          restrictions: { isInternal: false },
          step: {
            launchDate: null,
            applicationStartDate: null,
            finalSelectionDate: null,
            evaluationStartDate: null,
            closingDate: null,
            closeStepsAutomatically: true
          },
          files: []
        },
        style: {
          shortDescription: '',
          longDescription: ''
        },
        applicationForm: {
          id: undefined,
          sections: []
        },
        evaluation: {
          id: undefined,
          rounds: 1,
          likertScale: 5,
          steps: []
        }
      }

    default:
      return state
  }
}
