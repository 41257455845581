import React from 'react'

import { ROUTES, USER_FEATURES } from 'sharedKernel/constants'
import { IHubRouteBuilder } from './contract'

import { HubDashboardPage } from 'main/pages/hub/dashboard'
import { UserConfigPage } from 'main/pages/hub/user-config'
import { HubCompanyChallengePage } from 'main/pages/hub/company/challenge/all'
import { HubCompanyCalendarPage } from 'main/pages/hub/company/calendarpage'
import { HubCompanyChallengeTemplatePage } from 'main/pages/hub/company/challenge/template'
import { HubCompanyNewChallengePage } from 'main/pages/hub/company/challenge/new'
import { HubCompanyChallengeEditPage } from 'main/pages/hub/company/challenge/edit'
import { HubChallengeGetPage } from 'main/pages/hub/company/challenge/get'
import { HubCompanyEvaluatorsPage } from 'main/pages/hub/company/challenge/evaluators'
import { HubCompanyChallengeSolutionsPage } from 'main/pages/hub/company/challenge/solutions'
import { HubStartupChallengePage } from 'main/pages/hub/startup/challenge/all'
import { HubStartupChallengeGet } from 'main/pages/hub/startup/challenge/get'
import { HubStartupChallengeApplicationFormPage } from 'main/pages/hub/startup/challenge/apply'
import { HubStartupIdeaPage } from 'main/pages/hub/startup/idea/all'
import { HubStartupIdeaGet } from 'main/pages/hub/startup/idea/get'
import { HubStartupChallengeApplicationEditFormPage } from 'main/pages/hub/startup/challenge/edit'
import { HubEvaluatorChallengePage } from 'main/pages/hub/evaluator/challenge/all'
import { HubEvaluatorIdeaEvaluatePage } from 'main/pages/hub/evaluator/idea/evaluate'
import { HubEvaluatorInvitesPage } from 'main/pages/hub/evaluator/invites'
import { HubEvaluatorChallengeInfoPage } from 'main/pages/hub/evaluator/challenge/visualization'
import { HubCompanyChallengeSolutionsInfoPage } from 'main/pages/hub/company/challenge/solutions/info'

export const hubRoutes: IHubRouteBuilder[] = [
  {
    path: ROUTES.HUB.DASHBOARD,
    breadcrumb: 'Dashboard',
    component: <HubDashboardPage />,
    features: [USER_FEATURES.CHALLENGE]
  },
  {
    path: ROUTES.HUB.USER_CONFIG,
    breadcrumb: 'Configurações de usuário',
    component: <UserConfigPage />,
    features: [USER_FEATURES.CHALLENGE, USER_FEATURES.IDEA, USER_FEATURES.EVALUATION]
  },
  {
    path: ROUTES.HUB.COMPANY.CALENDAR,
    breadcrumb: 'Calendário',
    component: <HubCompanyCalendarPage />,
    features: [USER_FEATURES.CHALLENGE]
  },
  {
    path: ROUTES.HUB.COMPANY.CHALLENGE_LIST,
    breadcrumb: 'Programas e eventos',
    component: <HubCompanyChallengePage />,
    features: [USER_FEATURES.CHALLENGE]
  },
  {
    path: ROUTES.HUB.COMPANY.CHALLENGE_TEMPLATE,
    breadcrumb: 'Template do evento',
    component: <HubCompanyChallengeTemplatePage />,
    features: [USER_FEATURES.CHALLENGE]
  },
  {
    path: ROUTES.HUB.COMPANY.NEW_CHALLENGE,
    breadcrumb: 'Criar evento',
    component: <HubCompanyNewChallengePage />,
    features: [USER_FEATURES.CHALLENGE]
  },
  {
    path: ROUTES.HUB.COMPANY.GET_CHALLENGE,
    breadcrumb: 'Visualizar evento',
    component: <HubChallengeGetPage />,
    features: [USER_FEATURES.CHALLENGE],
    props: { params: [':challengeId'] }
  },
  {
    path: ROUTES.HUB.COMPANY.EDIT_CHALLENGE,
    breadcrumb: 'Editar evento',
    component: <HubCompanyChallengeEditPage />,
    features: [USER_FEATURES.CHALLENGE],
    props: { params: [':challengeId'] }
  },
  {
    path: ROUTES.HUB.COMPANY.CHALLENGE_EVALUATORS,
    breadcrumb: 'Gerenciar avaliadores',
    component: <HubCompanyEvaluatorsPage />,
    features: [USER_FEATURES.CHALLENGE],
    props: { params: [':challengeId'] }
  },
  {
    path: ROUTES.HUB.COMPANY.CHALLENGE_SOLUTIONS,
    breadcrumb: 'Gerenciar inscrições',
    component: <HubCompanyChallengeSolutionsPage />,
    features: [USER_FEATURES.CHALLENGE],
    props: { params: [':challengeId'] }
  },
  {
    path: ROUTES.HUB.STARTUP.CHALLENGE_LIST,
    breadcrumb: 'Eventos',
    component: <HubStartupChallengePage />,
    features: [USER_FEATURES.IDEA]
  },
  {
    path: ROUTES.HUB.STARTUP.GET_CHALLENGE,
    breadcrumb: 'Visualizar evento',
    component: <HubStartupChallengeGet />,
    features: [USER_FEATURES.IDEA],
    props: { params: [':challengeId'] }
  },
  {
    path: ROUTES.HUB.STARTUP.APPLY_CHALLENGE,
    breadcrumb: 'Inscrever-se no evento',
    component: <HubStartupChallengeApplicationFormPage />,
    features: [USER_FEATURES.IDEA],
    props: { params: [':challengeId'] }
  },
  {
    path: ROUTES.HUB.STARTUP.IDEA_LIST,
    breadcrumb: 'Minhas inscrições',
    component: <HubStartupIdeaPage />,
    features: [USER_FEATURES.IDEA]
  },
  {
    path: ROUTES.HUB.STARTUP.GET_IDEA,
    breadcrumb: 'Inscrição',
    component: <HubStartupIdeaGet />,
    features: [USER_FEATURES.IDEA],
    props: { params: [':ideaId'] }
  },
  {
    path: ROUTES.HUB.STARTUP.EDIT_IDEA,
    breadcrumb: 'Inscrição',
    component: <HubStartupChallengeApplicationEditFormPage />,
    features: [USER_FEATURES.IDEA],
    props: { params: [':ideaId', ':challengeId'] }
  },
  {
    path: ROUTES.HUB.EVALUATOR.CHALLENGE_LIST,
    breadcrumb: 'Minhas avaliações',
    component: <HubEvaluatorChallengePage />,
    features: [USER_FEATURES.EVALUATION]
  },
  {
    path: ROUTES.HUB.EVALUATOR.EVALUATE_IDEA,
    breadcrumb: 'Avaliar ideia',
    component: <HubEvaluatorIdeaEvaluatePage />,
    features: [USER_FEATURES.EVALUATION],
    props: { params: [':challengeId', ':stepId', ':ideaId'] }
  },
  {
    path: ROUTES.HUB.EVALUATOR.INVITES,
    breadcrumb: 'Meus convites',
    component: <HubEvaluatorInvitesPage />,
    features: [USER_FEATURES.EVALUATION]
  },
  {
    path: ROUTES.HUB.EVALUATOR.GET_CHALLENGE,
    breadcrumb: 'Visualizar evento',
    component: <HubEvaluatorChallengeInfoPage />,
    features: [USER_FEATURES.EVALUATION],
    props: { params: [':challengeId'] }
  },
  {
    path: ROUTES.HUB.COMPANY.CHALLENGE_SOLUTIONS_SUBSCRIPTIONS,
    breadcrumb: 'Visualizar Inscrição',
    component: <HubCompanyChallengeSolutionsInfoPage />,
    features: [USER_FEATURES.CHALLENGE],
    props: { params: [':challengeId', ':solutionId'] }
  }
]
