import { Row } from 'react-bootstrap'
import styled, { keyframes } from 'styled-components'

export const StepButtonsWrapper = styled(Row)`
    gap: 8px;
`
export const SectionTitle = styled.div<{ hasIcon: boolean }>`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding: 8px;
  color: var(--primary);
  text-align: center;
  border-bottom: 4px solid var(--primary);
  display: flex;
  align-items: center;
  justify-content: ${({ hasIcon }) => (hasIcon ? 'flex-start' : 'center')};

  span {
    margin-left: ${({ hasIcon }) => (hasIcon ? 'auto' : '0')};
    margin-right: ${({ hasIcon }) => (hasIcon ? 'auto' : '0')};
  }
`
const enterAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
`

const exitAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`

export const StepTransition = styled.div`
  &.enter {
    animation: ${enterAnimation} 0.5s forwards;
  }
  &.exit {
    animation: ${exitAnimation} 0.5s forwards;
  }
`
export const FormTitle = styled.p`
  font-family: 'Roboto';
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--orange-dark);
  text-align: center;
  margin-bottom: 0px;
`
