import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { Row } from 'react-bootstrap'
import { trans } from 'sharedKernel/i18n'
import { useNavigate } from 'react-router'
import { ROUTES } from 'sharedKernel/constants'

export const LoginButton = (): JSX.Element => {
  const navigate = useNavigate()

  const labels = {
    login: trans('Login'),
    alreadyHaveAccount: trans('Já tem cadastro? Faça o login.')
  }
  return (
        <Row className='px-2 mt-2'>
            <span className='mb-4 text-center'>{labels.alreadyHaveAccount}</span>
            <BaseButton onClick={() => navigate(ROUTES.USER_AUTH.LOGIN)} size="big" btnContent={labels.login} variant="risk-bg" />
        </Row>
  )
}
