/* ENVIRONMENT VARIABLES */

export const APP_ENVIRONMENT = process.env.REACT_APP_ENV
export const API_BASE_URL = process.env.REACT_APP_BASE_API_URL
export const NODE_ENVIRONMENT = process.env.NODE_ENV

/* BUSINESS */

export const USER_ROLES = {
  ADMIN: 'admin',
  COMPANY: 'company',
  RESOLVER: 'resolver',
  EVALUATOR: 'evaluator'
}

export const USER_FEATURES = {
  CHALLENGE: 'challenge',
  EVALUATION: 'evaluation',
  IDEA: 'idea'
}

export const USER_TERMS_TYPE = {
  USER: 'user',
  COMPANY: 'company',
  RESOLVER: 'resolver',
  EVALUATOR: 'evaluator'
}

export const SOLUTION_STATUS = {
  APPROVED: 'Approved',
  DISAPPROVED: 'Archived',
  CREATED: 'Created',
  LAUNCHED: 'Launched',
  CANCELED: 'Canceled'
}

export const EVALUATOR_INVITE_STATUS = {
  INVITED: 'invited',
  ACCEPTED: 'accepted',
  REFUSED: 'rejected',
  CANCELED: 'canceled'
}

export const COMPANY_EVALUATION_INVITE_STATUS = {
  ACCEPTED: 'accepted',
  INVITED: 'invited',
  ERROR: 'error',
  REFUSED: 'rejected',
  EXPIRED: 'expired',
  CANCELED: 'canceled'
}

export const EVALUATION_STATUS = {
  CREATED: 'created',
  PENDING: 'pending',
  EVALUATED: 'evaluated',
  NOT_ACCEPTED: 'not accepted',
  CANCELED: 'canceled'
}

/* ROUTE DOMAIN */

export const ROUTES = {
  NOT_FOUND: '*',
  HOTSITE: {
    ROOT: '/',
    MARKETPLACE: '/marketplace',
    REGISTER: {
      EVALUATOR: '/register/evaluator/:challengeId',
      RESOLVER: '/register/resolver'
    },
    CONTACT: '/contact',
    CHALLENGE: '/challenge/:challengeId',
    LANDINGPAGE: '/mapadainovacao'
  },
  USER_AUTH: {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/user/reset/:userId/:token'
  },
  HUB: {
    ROOT: '/hub',
    DASHBOARD: '/dashboard',
    USER_CONFIG: '/user-config',
    COMPANY: {
      CHALLENGE_LIST: '/company/challenge',
      CALENDAR: '/company/calendar',
      CHALLENGE_TEMPLATE: '/company/challenge/template',
      NEW_CHALLENGE: '/company/challenge/template/new',
      EDIT_CHALLENGE: '/company/challenge/:challengeId/edit',
      GET_CHALLENGE: '/company/challenge/:challengeId',
      CHALLENGE_EVALUATORS: '/company/challenge/:challengeId/evaluators',
      CHALLENGE_SOLUTIONS: '/company/challenge/:challengeId/solutions',
      CHALLENGE_SOLUTIONS_SUBSCRIPTIONS: '/company/challenge/:challengeId/solutions/:solutionId/subscription'
    },
    STARTUP: {
      CHALLENGE_LIST: '/startup/challenge',
      GET_CHALLENGE: '/startup/challenge/:challengeId',
      APPLY_CHALLENGE: '/startup/challenge/:challengeId/apply',
      IDEA_LIST: '/startup/idea',
      GET_IDEA: '/startup/idea/:ideaId',
      EDIT_IDEA: '/startup/idea/:ideaId/challenge/:challengeId'
    },
    EVALUATOR: {
      CHALLENGE_LIST: '/evaluator/challenge',
      GET_CHALLENGE: '/evaluator/challenge/:challengeId',
      EVALUATE_IDEA: '/evaluator/challenge/:challengeId/step/:stepId/idea/:ideaId',
      INVITES: '/evaluator/invites'
    }
  }
}

/* INPUT MASKS */

export const INPUT_MASK_FORMAT = {
  CEP: '00000-000',
  PHONE: '(00) 0000-0000',
  MOBILE: '(00) 00000-0000',
  CPF: '000.000.000-00',
  CNPJ: '00.000.000/0000-00'
}

/* DROPDOWN */
export const DROPDOWN_COUNTRIES = [
  { value: '', label: 'Selecione' },
  { value: 'AF', label: 'Afeganistão' },
  { value: 'ZA', label: 'África do Sul' },
  { value: 'AL', label: 'Albânia' },
  { value: 'DE', label: 'Alemanha' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'SA', label: 'Arábia Saudita' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armênia' },
  { value: 'AU', label: 'Austrália' },
  { value: 'AT', label: 'Áustria' },
  { value: 'AZ', label: 'Azerbaijão' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BH', label: 'Bahrein' },
  { value: 'BY', label: 'Bielorrússia' },
  { value: 'BE', label: 'Bélgica' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benim' },
  { value: 'BO', label: 'Bolívia' },
  { value: 'BR', label: 'Brasil' },
  { value: 'BG', label: 'Bulgária' },
  { value: 'CA', label: 'Canadá' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CO', label: 'Colômbia' },
  { value: 'KP', label: 'Coreia do Norte' },
  { value: 'KR', label: 'Coreia do Sul' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CU', label: 'Cuba' },
  { value: 'DK', label: 'Dinamarca' },
  { value: 'EG', label: 'Egito' },
  { value: 'AE', label: 'Emirados Árabes Unidos' },
  { value: 'EC', label: 'Equador' },
  { value: 'ES', label: 'Espanha' },
  { value: 'US', label: 'Estados Unidos' },
  { value: 'FR', label: 'França' },
  { value: 'GR', label: 'Grécia' },
  { value: 'IN', label: 'Índia' },
  { value: 'ID', label: 'Indonésia' },
  { value: 'IT', label: 'Itália' },
  { value: 'JP', label: 'Japão' },
  { value: 'MX', label: 'México' },
  { value: 'NG', label: 'Nigéria' },
  { value: 'NL', label: 'Países Baixos' },
  { value: 'PT', label: 'Portugal' },
  { value: 'RU', label: 'Rússia' },
  { value: 'CH', label: 'Suíça' },
  { value: 'TR', label: 'Turquia' },
  { value: 'UA', label: 'Ucrânia' },
  { value: 'GB', label: 'Reino Unido' },
  { value: 'VE', label: 'Venezuela' }
]

export const DROPDOWN_BRAZIL_STATES = [
  { value: '', label: 'Selecione' },
  { value: 'AC', label: 'AC' },
  { value: 'AP', label: 'AP' },
  { value: 'AL', label: 'AL' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' }
]

export const DROPDOWN_ACTIVITY = [
  { value: '', label: 'Selecione' },
  { value: 'Tech', label: 'Tech' },
  { value: 'EdTech', label: 'EdTech' },
  { value: 'HealthTech', label: 'HealthTech' },
  { value: 'AgTech', label: 'AgTech' },
  { value: 'FoodTech', label: 'FoodTech' },
  { value: 'MobilityTech', label: 'MobilityTech' },
  { value: 'FinTech', label: 'FinTech' },
  { value: 'eCommerce', label: 'eCommerce' },
  { value: 'EnergyTech', label: 'EnergyTech' },
  { value: 'CleanTech', label: 'CleanTech' },
  { value: 'Wellness', label: 'Wellness' },
  { value: 'PropTech', label: 'PropTech' },
  { value: 'Outro', label: 'Outro' }
]

export const DROPDOWN_BUSINESS_MODEL = [
  { value: '', label: 'Selecione' },
  { value: 'B2B', label: 'Somente B2B' },
  { value: 'B2C', label: 'Somente B2C' },
  { value: 'B2G', label: 'Somente B2G' },
  { value: 'B2B e B2C', label: 'B2B e B2C' },
  { value: 'B2B e B2G', label: 'B2B e B2G' },
  { value: 'B2B, B2C e B2G', label: 'B2B, B2C e B2G' }
]

export const DROPDOWN_NUMBER_EMPLOYEES = [
  { value: '', label: 'Selecione' },
  { value: '0-1', label: '0-1 funcionários' },
  { value: '2-10', label: '2-10 funcionários' },
  { value: '11-50', label: '11-50 funcionários' },
  { value: '51-200', label: '51-200 funcionários' },
  { value: '200', label: '200+ funcionários' }
]

export const DROPDOWN_TECHNOLOGIES = [
  { value: '', label: 'Selecione' },
  { value: 'JavaScript', label: 'JavaScript' },
  { value: 'TypeScript', label: 'TypeScript' },
  { value: 'Python', label: 'Python' },
  { value: 'Java', label: 'Java' },
  { value: 'C#', label: 'C#' },
  { value: 'PHP', label: 'PHP' },
  { value: 'Ruby', label: 'Ruby' },
  { value: 'Go', label: 'Go' },
  { value: 'Swift', label: 'Swift' },
  { value: 'Kotlin', label: 'Kotlin' },
  { value: 'Objective-C', label: 'Objective-C' },
  { value: 'C++', label: 'C++' },
  { value: 'C', label: 'C' },
  { value: 'Rust', label: 'Rust' },
  { value: 'Scala', label: 'Scala' },
  { value: 'Perl', label: 'Perl' },
  { value: 'R', label: 'R' },
  { value: 'Elixir', label: 'Elixir' },
  { value: 'Dart', label: 'Dart' },
  { value: 'Haskell', label: 'Haskell' },
  { value: 'MATLAB', label: 'MATLAB' },
  { value: 'Shell', label: 'Shell' },
  { value: 'PowerShell', label: 'PowerShell' },
  { value: 'HTML', label: 'HTML' },
  { value: 'CSS', label: 'CSS' },
  { value: 'Sass', label: 'Sass' },
  { value: 'Less', label: 'Less' },
  { value: 'SQL', label: 'SQL' },
  { value: 'NoSQL', label: 'NoSQL' },
  { value: 'GraphQL', label: 'GraphQL' },
  { value: 'React', label: 'React' },
  { value: 'Angular', label: 'Angular' },
  { value: 'Vue.js', label: 'Vue.js' },
  { value: 'Svelte', label: 'Svelte' },
  { value: 'Ember.js', label: 'Ember.js' },
  { value: 'Backbone.js', label: 'Backbone.js' },
  { value: 'jQuery', label: 'jQuery' },
  { value: 'ASP.NET', label: 'ASP.NET' },
  { value: 'Spring', label: 'Spring' },
  { value: 'Django', label: 'Django' },
  { value: 'Flask', label: 'Flask' },
  { value: 'Laravel', label: 'Laravel' },
  { value: 'Symfony', label: 'Symfony' },
  { value: 'Ruby on Rails', label: 'Ruby on Rails' },
  { value: 'Express', label: 'Express' },
  { value: 'NestJS', label: 'NestJS' },
  { value: 'Next.js', label: 'Next.js' },
  { value: 'Nuxt.js', label: 'Nuxt.js' },
  { value: 'Gatsby', label: 'Gatsby' },
  { value: 'Meteor', label: 'Meteor' },
  { value: 'Flutter', label: 'Flutter' },
  { value: 'React Native', label: 'React Native' },
  { value: 'Ionic', label: 'Ionic' },
  { value: 'Xamarin', label: 'Xamarin' },
  { value: 'Unity', label: 'Unity' },
  { value: 'Unreal Engine', label: 'Unreal Engine' }
]

export const DROPDOWN_DEVELOPMENT_STAGE = [
  { value: '', label: 'Selecione' },
  { value: '1', label: 'Ainda não realizei vendas do produto' },
  { value: '2', label: 'Já realizei a primeira venda' },
  { value: '3', label: 'Já tenho vendas recorrentes' }
]

export const RESOLVER_DROPDOWN_ROLES = [
  { value: '', label: 'Selecione' },
  { value: 'Sócio(a)/Fundador(a)', label: 'Sócio(a)/Fundador(a)' },
  { value: 'Presidente/Vice-Presidente', label: 'Presidente/Vice-Presidente' },
  { value: 'Diretor(a)', label: 'Diretor(a)' },
  { value: 'Coordenador(a)', label: 'Coordenador(a)' },
  { value: 'Analista', label: 'Analista' },
  { value: 'Técnico(a)', label: 'Técnico(a)' },
  { value: 'Assistente', label: 'Assistente' },
  { value: 'Estagiário(a)', label: 'Estagiário(a)' },
  { value: 'Estudante', label: 'Estudante' }
]

export const RESOLVER_INVESTMENTE_SOURCE = [
  { value: '', label: 'Selecione' },
  { value: 'Investidor-anjo', label: 'Investidor-anjo' },
  { value: 'Capital semente', label: 'Capital semente' },
  { value: 'Private equity', label: 'Private equity' },
  { value: 'Aceleradoras', label: 'Aceleradoras' },
  { value: 'Aceleradora', label: 'Aceleradora' },
  { value: 'Venture capital', label: 'Venture capital' },
  { value: 'Outro', label: 'Outro' }
]
export const NUMBER_OF_SUBSCRIBE_OPTIONS: any[] = [
  {
    id: 'once',
    value: '1',
    label: 'Apenas uma vez',
    isDeleted: false,
    path: ''
  },
  {
    id: 'twice',
    value: '2',
    label: 'Duas vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'three',
    value: '3',
    label: 'Três vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'four',
    value: '4',
    label: 'Quatro vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'five',
    value: '5',
    label: 'Cinco vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'six',
    value: '6',
    label: 'Seis vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'seven',
    value: '7',
    label: 'Sete vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'eight',
    value: '8',
    label: 'Oito vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'nine',
    value: '9',
    label: 'Nove vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'ten',
    value: '1000',
    label: 'Infinitas vezes',
    isDeleted: false,
    path: ''
  }

]
