import { RESET_EVALUATOR_REGISTER_FORM, RESET_REGISTER_FORM, UPDATE_EVALUATOR_REGISTER_FORM, UPDATE_REGISTER_FORM } from './actions'
import { IHotsiteEvaluatorRegisterForm, IHotsiteRegisterForm } from './contracts'

export const REGISTER_FORM_INITIAL_STATE: IHotsiteRegisterForm = {
  form: {
    lineOne: { value: '', isRequired: true, isInvalid: false, message: '' },
    lineTwo: { value: '', isRequired: false, isInvalid: false, message: '' },
    number: { value: '', isRequired: true, isInvalid: false, message: '' },
    zipcode: { value: '', isRequired: false, isInvalid: false, message: '' },
    city: { value: '', isRequired: true, isInvalid: false, message: '' },
    state: { value: '', isRequired: true, isInvalid: false, message: '' },
    country: { value: '', isRequired: true, isInvalid: false, message: '' },
    startupName: { value: '', isRequired: true, isInvalid: false, message: '' },
    contactName: { value: '', isRequired: true, isInvalid: false, message: '' },
    email: { value: '', isRequired: true, isInvalid: false, message: '' },
    cnpj: { value: '', isRequired: false, isInvalid: false, message: '' },
    pitch: { value: '', isRequired: true, isInvalid: false, message: '' },
    startupWebsite: { value: '', isRequired: false, isInvalid: false, message: '' },
    phone: { value: '', isRequired: true, isInvalid: false, message: '' },
    password: { value: '', isRequired: true, isInvalid: false, message: '' },
    confirmPassword: { value: '', isRequired: true, isInvalid: false, message: '' },
    activity: { value: '', isRequired: false, isInvalid: false, message: '' },
    otherActivity: { value: '', isRequired: false, isInvalid: false, message: '' },
    businessModel: { value: '', isRequired: false, isInvalid: false, message: '' },
    numberEmployees: { value: '', isRequired: true, isInvalid: false, message: '' },
    technologies: { value: '', isRequired: false, isInvalid: false, message: '' },
    developmentStage: { value: '', isRequired: false, isInvalid: false, message: '' },
    annualTurnover: { value: '', isRequired: false, isInvalid: false, message: '' },
    hasRaisedInvestment: { value: '', isRequired: false, isInvalid: false, message: '' },
    amount: { value: '', isRequired: false, isInvalid: false, message: '' },
    investmentSource: { value: '', isRequired: false, isInvalid: false, message: '' },
    otherInvestmentSource: { value: '', isRequired: false, isInvalid: false, message: '' }
  },
  acceptUserTerms: false,
  isFormValid: false
}

export const EVALUATOR_REGISTER_FORM_INITIAL_STATE: any = {
  form: {
    lineOne: { value: '', isRequired: true, isInvalid: false, message: '' },
    lineTwo: { value: '', isRequired: false, isInvalid: false, message: '' },
    number: { value: '', isRequired: true, isInvalid: false, message: '' },
    zipcode: { value: '', isRequired: false, isInvalid: false, message: '' },
    city: { value: '', isRequired: true, isInvalid: false, message: '' },
    state: { value: '', isRequired: true, isInvalid: false, message: '' },
    contactName: { value: '', isRequired: true, isInvalid: false, message: '' },
    email: { value: '', isRequired: true, isInvalid: false, message: '' },
    phone: { value: '', isRequired: true, isInvalid: false, message: '' },
    password: { value: '', isRequired: true, isInvalid: false, message: '' },
    confirmPassword: { value: '', isRequired: true, isInvalid: false, message: '' }
  },
  acceptUserTerms: false,
  isFormValid: false
}
export interface IHotsiteRegisterFormStateAction {
  type: string
  payload: IHotsiteRegisterForm
}

export interface IHotsiteEvaluatorRegisterFormStateAction {
  type: string
  payload: IHotsiteEvaluatorRegisterForm
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const HotsiteRegisterFormReducer = (
  state: IHotsiteRegisterForm = REGISTER_FORM_INITIAL_STATE,
  action: IHotsiteRegisterFormStateAction
): any => {
  switch (action.type) {
    case UPDATE_REGISTER_FORM:
      return {
        ...state,
        ...action.payload
      }

    case RESET_REGISTER_FORM:
      return {
        form: {
          lineOne: { value: '', isRequired: true, isInvalid: false, message: '' },
          lineTwo: { value: '', isRequired: false, isInvalid: false, message: '' },
          number: { value: '', isRequired: true, isInvalid: false, message: '' },
          zipcode: { value: '', isRequired: false, isInvalid: false, message: '' },
          city: { value: '', isRequired: true, isInvalid: false, message: '' },
          state: { value: '', isRequired: true, isInvalid: false, message: '' },
          country: { value: '', isRequired: true, isInvalid: false, message: '' },
          startupName: { value: '', isRequired: true, isInvalid: false, message: '' },
          contactName: { value: '', isRequired: true, isInvalid: false, message: '' },
          email: { value: '', isRequired: true, isInvalid: false, message: '' },
          cnpj: { value: '', isRequired: false, isInvalid: false, message: '' },
          pitch: { value: '', isRequired: true, isInvalid: false, message: '' },
          startupWebsite: { value: '', isRequired: false, isInvalid: false, message: '' },
          phone: { value: '', isRequired: true, isInvalid: false, message: '' },
          password: { value: '', isRequired: true, isInvalid: false, message: '' },
          confirmPassword: { value: '', isRequired: true, isInvalid: false, message: '' },
          activity: { value: '', isRequired: false, isInvalid: false, message: '' },
          otherActivity: { value: '', isRequired: false, isInvalid: false, message: '' },
          businessModel: { value: '', isRequired: false, isInvalid: false, message: '' },
          numberEmployees: { value: '', isRequired: true, isInvalid: false, message: '' },
          technologies: { value: '', isRequired: false, isInvalid: false, message: '' },
          developmentStage: { value: '', isRequired: false, isInvalid: false, message: '' },
          annualTurnover: { value: '', isRequired: false, isInvalid: false, message: '' },
          hasRaisedInvestment: { value: '', isRequired: false, isInvalid: false, message: '' },
          amount: { value: '', isRequired: false, isInvalid: false, message: '' },
          investmentSource: { value: '', isRequired: false, isInvalid: false, message: '' },
          otherInvestmentSource: { value: '', isRequired: false, isInvalid: false, message: '' }
        },
        acceptUserTerms: false,
        isFormValid: false
      }

    default:
      return state
  }
}

export const HotsiteEvaluatorRegisterFormReducer = (
  state: IHotsiteEvaluatorRegisterForm = EVALUATOR_REGISTER_FORM_INITIAL_STATE,
  action: IHotsiteEvaluatorRegisterFormStateAction
): any => {
  switch (action.type) {
    case UPDATE_EVALUATOR_REGISTER_FORM:
      return {
        ...state,
        ...action.payload
      }

    case RESET_EVALUATOR_REGISTER_FORM:
      return {
        form: {
          lineOne: { value: '', isRequired: true, isInvalid: false, message: '' },
          lineTwo: { value: '', isRequired: false, isInvalid: false, message: '' },
          number: { value: '', isRequired: true, isInvalid: false, message: '' },
          zipcode: { value: '', isRequired: false, isInvalid: false, message: '' },
          city: { value: '', isRequired: true, isInvalid: false, message: '' },
          state: { value: '', isRequired: true, isInvalid: false, message: '' },
          contactName: { value: '', isRequired: true, isInvalid: false, message: '' },
          email: { value: '', isRequired: true, isInvalid: false, message: '' },
          phone: { value: '', isRequired: true, isInvalid: false, message: '' },
          password: { value: '', isRequired: true, isInvalid: false, message: '' },
          confirmPassword: { value: '', isRequired: true, isInvalid: false, message: '' }
        },
        acceptUserTerms: false,
        isFormValid: false
      }

    default:
      return state
  }
}
