import { IHubChallengeBasicFormValidationState } from './contracts'
import { NotEmpty, Sections, ShortText, Title } from 'sharedKernel/validations/'
import { Youtube } from 'sharedKernel/validations/specific/youtube'
import { DynamicField } from 'sharedKernel/validations/specific/dynamicField'

export const CHALLENGE_BASIC_FORM_VALIDATIONS_SET_STATE = 'CHALLENGE_BASIC_FORM_VALIDATIONS_SET_STATE'
export const CHALLENGE_BASIC_FORM_VALIDATIONS_STATE: IHubChallengeBasicFormValidationState = {
  applicationForm: {
    isValid: true,
    message: '',
    validations: [
      {
        stages: ['save', 'launch'],
        validation: new Sections()
      }
    ],
    name: {
      isValid: true,
      message: '',
      validations: []
    },
    sections: [
      {
        name: {
          isValid: true,
          message: '',
          validations: [
            {
              stages: [],
              validation: new NotEmpty()
            }
          ]
        },
        order: {
          isValid: true,
          message: '',
          validations: []
        },
        fields: [
          {
            validations: [
              {
                stages: ['save', 'launch'],
                validation: new DynamicField()
              }
            ],
            isValid: true,
            message: '',
            type: {
              isValid: true,
              message: '',
              validations: []
            },
            name: {
              isValid: true,
              message: '',
              validations: []
            },
            order: {
              isValid: true,
              message: '',
              validations: []
            },
            size: {
              isValid: true,
              message: '',
              validations: []
            },
            options: []
          },
          {
            validations: [
              {
                stages: ['save', 'launch'],
                validation: new DynamicField()
              }
            ],
            isValid: true,
            message: '',
            type: {
              isValid: true,
              message: '',
              validations: []
            },
            name: {
              isValid: true,
              message: '',
              validations: []
            },
            order: {
              isValid: true,
              message: '',
              validations: []
            },
            size: {
              isValid: true,
              message: '',
              validations: []
            },
            options: []
          }
        ]
      }
    ]
  },
  evaluation: {
    steps: [
      {
        name: {
          isValid: true,
          message: '',
          validations: [
            {
              stages: ['launch'],
              validation: new NotEmpty()
            }
          ]
        },
        sections: [
          {
            name: {
              isValid: true,
              message: '',
              validations: [
                {
                  stages: ['launch'],
                  validation: new NotEmpty()
                }
              ]
            },
            fields: []
          }
        ]
      }
    ]
  },
  config: {
    step: {
      applicationStartDate: {
        isValid: true,
        message: '',
        validations: [
          {
            stages: ['launch'],
            validation: new NotEmpty()
          }
        ]
      },
      closingDate: {
        isValid: true,
        message: '',
        validations: [
          {
            stages: ['launch'],
            validation: new NotEmpty()
          }
        ]
      },
      finalSelectionDate: {
        isValid: true,
        message: '',
        validations: [
          {
            stages: ['launch'],
            validation: new NotEmpty()
          }
        ]
      },
      evaluationStartDate: {
        isValid: true,
        message: '',
        validations: [
          {
            stages: ['launch'],
            validation: new NotEmpty()
          }
        ]
      },
      launchDate: {
        isValid: true,
        message: '',
        validations: [
          {
            stages: ['launch'],
            validation: new NotEmpty()
          }
        ]
      }
    },
    title: {
      isValid: true,
      message: '',
      validations: [
        {
          stages: ['save', 'launch'],
          validation: new Title()
        }
      ]
    }
  },
  style: {
    shortDescription: {
      isValid: true,
      message: '',
      validations: [
        {
          stages: ['save', 'launch'],
          validation: new ShortText()
        }
      ]
    },
    youtubeLink: {
      isValid: true,
      message: '',
      validations: [
        {
          stages: [],
          validation: new Youtube()
        }
      ]
    },
    youtubeLink2: {
      isValid: true,
      message: '',
      validations: [
        {
          stages: [],
          validation: new Youtube()
        }
      ]
    },
    youtubeLink3: {
      isValid: true,
      message: '',
      validations: [
        {
          stages: [],
          validation: new Youtube()
        }
      ]
    }
  }
}

export const CHALLENGE_BASIC_FORM_VALIDATIONS_INITIAL_STATE: IHubChallengeBasicFormValidationState = {
  applicationForm: {
    isValid: true,
    message: '',
    validations: [
      {
        stages: ['save', 'launch'],
        validation: new Sections()
      }
    ],
    name: {
      isValid: true,
      message: '',
      validations: []
    },
    sections: []
  },
  evaluation: {
    steps: []
  },
  config: {
    step: {
      applicationStartDate: {
        isValid: true,
        message: '',
        validations: [
          {
            stages: ['launch'],
            validation: new NotEmpty()
          }
        ]
      },
      closingDate: {
        isValid: true,
        message: '',
        validations: [
          {
            stages: ['launch'],
            validation: new NotEmpty()
          }
        ]
      },
      finalSelectionDate: {
        isValid: true,
        message: '',
        validations: [
          {
            stages: ['launch'],
            validation: new NotEmpty()
          }
        ]
      },
      evaluationStartDate: {
        isValid: true,
        message: '',
        validations: [
          {
            stages: ['launch'],
            validation: new NotEmpty()
          }
        ]
      },
      launchDate: {
        isValid: true,
        message: '',
        validations: [
          {
            stages: ['launch'],
            validation: new NotEmpty()
          }
        ]
      }
    },
    title: {
      isValid: true,
      message: '',
      validations: [
        {
          stages: ['save', 'launch'],
          validation: new Title()
        }
      ]
    }
  },
  style: {
    shortDescription: {
      isValid: true,
      message: '',
      validations: [
        {
          stages: ['save', 'launch'],
          validation: new ShortText()
        }
      ]
    },
    youtubeLink: {
      isValid: true,
      message: '',
      validations: [
        {
          stages: [],
          validation: new Youtube()
        }
      ]
    },
    youtubeLink2: {
      isValid: true,
      message: '',
      validations: [
        {
          stages: [],
          validation: new Youtube()
        }
      ]
    },
    youtubeLink3: {
      isValid: true,
      message: '',
      validations: [
        {
          stages: [],
          validation: new Youtube()
        }
      ]
    }
  }
}

const HubChallengeBasicFormValidationSetState = (payload?: IHubChallengeBasicFormValidationState): any => {
  return {
    type: CHALLENGE_BASIC_FORM_VALIDATIONS_SET_STATE,
    payload: payload
  }
}

export { HubChallengeBasicFormValidationSetState }
