/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import _ from 'lodash'

import { PostFormat } from 'sharedKernel/api'
import { regexPatterns } from 'sharedKernel/regex'
import { IRegisterFormValues } from '../store'

export const createRegisterRequest = (form: IRegisterFormValues, url: string, acceptUserTerms: boolean): PostFormat => {
  const request: PostFormat = {
    url,
    data: {}
  }

  request.data = transformFormDataToPostObject(form, acceptUserTerms)
  return request
}

const transformFormDataToPostObject = (form: IRegisterFormValues, acceptUserTerms: boolean): any => {
  const configFormArray = Object.entries(form)
  const entriesArray: any = []

  configFormArray.forEach((pair) => {
    entriesArray.push([pair[0], pair[1].value])
  })

  const cleanObject = Object.fromEntries(entriesArray)

  const data = {
    name: cleanObject.startupName,
    contactName: cleanObject.contactName,
    contact: {
      mobile: cleanObject.phone.replace(regexPatterns.clearMaskedNumbers, '')
    },
    email: cleanObject.email,
    cnpj: cleanCNPJ(cleanObject.cnpj),
    website: cleanObject.startupWebsite,
    password: cleanObject.password,
    confirmPassword: cleanObject.confirmPassword,
    address: getAddress(cleanObject),
    country: cleanObject.country,
    isUserTermsAccepted: acceptUserTerms,
    businessModel: cleanObject.businessModel,
    numberEmployees: cleanObject.numberEmployees,
    technologies: cleanObject.technologies,
    developmentStage: cleanObject.developmentStage,
    annualTurnover: cleanObject.annualTurnover,
    hasRaisedInvestment: Boolean(cleanObject.hasRaisedInvestment),
    amount: cleanObject.amount,
    activity: cleanObject.activityOther?.trim() ? cleanObject.activityOther : cleanObject.activity,
    investmentSource: cleanObject.otherInvestmentSource.trim() ? cleanObject.otherInvestmentSource : cleanObject.investmentSource,
    pitch: cleanObject.pitch

  }

  if (cleanObject.startupName == null) {
    delete data.name
  }

  return { data: data }
}

const getAddress = (cleanObject: any): any => {
  cleanObject.zipcode = cleanObject.zipcode.replace(regexPatterns.clearMaskedNumbers, '')

  return _.pick(cleanObject, ['zipcode', 'lineOne', 'number', 'lineTwo', 'city', 'state'])
}

const cleanCNPJ = (cnpj: string): string => {
  if (cnpj == null) {
    return ''
  }
  return cnpj.replace(/[^\d]/g, '')
}
