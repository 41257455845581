import styled from 'styled-components'

export const FormTitle = styled.div`
  margin-top: 51px;
  h1 {
    font-size: 22px;
  }
`

export const LoadindContainer = styled.div`
  height: 40rem;
`

export const ScreenContainer = styled.div`
  width: 90%;
  padding: 3rem 0;
  margin: auto;
`
export const FormIdeaSectionName = styled.h4`
  font-size: 1.5rem;
`

export const FormIdeaAnswer = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1.75rem;
  text-align: left;
`

export const FormIdeaSections = styled.div`
  padding-top: 0.5rem;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
  border-radius: 0.38rem;
  background-color: var(--white);
`
export const FormChallengeHeader = styled.div`
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 79px;
  z-index: 5;

  background-color: var(--background);
  box-shadow: 0 5px 2px -2px var(--shadow-smooth), 32px 0 var(--background), -32px 0 var(--background);
`

export const ChangeDataButtonArea = styled.div`
  position: absolute;
  right: 3vw;
`
