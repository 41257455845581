import { CustomCalendar } from 'components/CustomCalendar'
import React from 'react'
import { useNavigate } from 'react-router'
import { GetFormat, useGet } from 'sharedKernel/api'
import { Challenge } from 'data/contracts'
import { Loading } from 'components/loading'

export const HubCompanyCalendarPage = ({ ...props }): JSX.Element => {
  const navigate = useNavigate()

  const request: GetFormat = {
    url: 'v1/hub/company/challenge'
  }

  const { data, isFetching, refetch } = useGet<Challenge[]>(request, 'company-challenges')
  const renderResponse = (): JSX.Element => {
    // eslint-disable-next-line no-constant-condition
    if (isFetching) {
      return (
          <Loading />
      )
    }

    return (
        <>
            <CustomCalendar challengeList={data.rows}/>
        </>
    )
  }
  return <>{renderResponse()}</>
}
