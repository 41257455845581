import React, { useMemo } from 'react'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useNavigate } from 'react-router'
import 'moment/locale/pt-br'

const localizer = momentLocalizer(moment)
interface Event {
  id: string
  title: string
  start: Date
  end: Date
  desc: string
  color: string
}

export const CustomCalendar = ({ challengeList }): JSX.Element => {
  const navigate = useNavigate()
  const DragAndDropCalendar = withDragAndDrop(Calendar)

  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: moment.now(),
      views: {
        month: true,
        week: true,
        day: true
      }
    }), []
  )

  const eventStyle = (event): any => ({
    style: {
      backgroundColor: event.color
    }
  })

  const handleEventClick = (event: Event): void => {
    navigate(`/hub/company/challenge/${event.id}`)
  }

  const calendarEvents = challengeList.map(data => ({
    id: data.id,
    title: data.config.title,
    start: new Date(data.config.step.applicationStartDate),
    end: new Date(data.config.step.applicationStartDate),
    desc: data.style.shortDescription,
    color: '#5856d6'
  }))

  return (
    <div className='tela' >
      <div className='toolbar p-4' style={{ maxHeight: 'auto', overflowY: 'auto' }}>
      </div>

      <div className='calendario'>
        <DragAndDropCalendar
          defaultView='month'
          events={calendarEvents}
          defaultDate={defaultDate}
          localizer={localizer}
          style={{ height: 500, width: '95%' }}
          resizable
          messages={{
            next: '>',
            previous: '<',
            today: 'Hoje',
            month: 'Mês',
            week: 'Semana',
            day: 'Dia'
          }}
          culture='pt-br'
          views={views}
          className='calendar'
          eventPropGetter={eventStyle}
          onSelectEvent={handleEventClick}
        />
      </div>
    </div>
  )
}
