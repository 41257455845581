import { IAuthState, AuthReducer } from 'sharedKernel/auth'
import { combineReducers } from 'redux'
import { ThemeReducer, IThemeState } from 'sharedKernel/theme'
import {
  HubChallengeBasicFormReducer,
  HubChallengeBasicFormValidationReducer,
  IHubChallengeBasicFormState,
  IHubChallengeBasicFormValidationState,
  resetChallengeSection
} from 'domain/feature/hub/company/challenge/FormWrapper/store'
import {
  HubChallengeBasicFormFilesReducer,
  IHubChallengeBasicFormFilesState,
  resetChallengeFiles
} from 'domain/feature/hub/company/challenge/FormWrapper/files/store'
import {
  HubStartupChallengeApplicationForm,
  IHubStartupChallengeApplicationForm,
  IHubStartupChallengeApplicationFilesState,
  HubStartupChallengeApplicationFiles
} from 'domain/feature/hub/startup/challenge/applicationForm/store'
import { ISidebarState, SidebarReducer } from 'components/menu/side/store'
import { MenuEvaluatorsReducer } from 'components/menu/state/content/store'
import { EvaluationMenuReducer, IEvaluationMenuState } from 'components/menu/state/control/store'
import { solutionReducer } from 'domain/feature/hub/evaluator/challenge/store'
import { HubPasswordFormReducer, IPasswordForm } from 'domain/feature/hub/user-config/ChangePasswordForm/store'
import {
  IUserConfigForm,
  HubUserConfigFormReducer
} from 'domain/feature/hub/user-config/PersonalConfigForm/store/configForm'
import {
  HubProfileImageReducer,
  IProfileImage
} from 'domain/feature/hub/user-config/PersonalConfigForm/store/profileImage'
import { HotsiteChallengesReducer, HotsiteChallengeProps } from 'domain/feature/hotsite/store'
import { IHotsiteRegisterForm, HotsiteRegisterFormReducer, IHotsiteEvaluatorRegisterForm, HotsiteEvaluatorRegisterFormReducer } from 'domain/feature/hotsite/Forms/Register/store'
import { IHotsiteContactForm, HotsiteContactFormReducer } from 'domain/feature/hotsite/Forms/Contact/store'
import { evaluatorSolutionReducer, IEvaluatorSolutionState } from 'domain/feature/hub/company/challenge/evaluators/assign/store'
import { ILandingPageRecommendationForm, HotsiteRecommendationFormReducer } from 'domain/feature/hotsite/LandingPage/LandingPageForm/store'

export type TReducers = {
  isDarkModeOn: IThemeState
  hubChallengeBasicFormState: IHubChallengeBasicFormState
  hubChallengeBasicFormValidationState: IHubChallengeBasicFormValidationState
  hubChallengeBasicFormFilesState: IHubChallengeBasicFormFilesState
  startupHubChallengeApplicationFormState: IHubStartupChallengeApplicationForm
  startupHubChallengeApplicationFormFilesState: IHubStartupChallengeApplicationFilesState
  auth: IAuthState
  sidebarState: ISidebarState
  menuEvaluatorsState: any
  isEvaluationMenu: IEvaluationMenuState
  solutionReducer: any
  evaluatorSolutionState: IEvaluatorSolutionState
  hubIUserConfigFormState: IUserConfigForm
  hubIPasswordState: IPasswordForm
  hubIProfileImage: IProfileImage
  hotsiteChallengesData: HotsiteChallengeProps
  hotsiteIHotsiteRegisterFormState: IHotsiteRegisterForm
  hotsiteIHotsiteEvaluatorRegisterFormState: IHotsiteEvaluatorRegisterForm
  hotsiteIHotsiteContactFormState: IHotsiteContactForm
  hotsiteILandingPageRecommendationFormState: ILandingPageRecommendationForm
}

const reducers = combineReducers({
  isDarkModeOn: ThemeReducer,
  isEvaluationMenu: EvaluationMenuReducer,
  hubChallengeBasicFormState: HubChallengeBasicFormReducer,
  hubChallengeBasicFormValidationState: HubChallengeBasicFormValidationReducer,
  startupHubChallengeApplicationFormState: HubStartupChallengeApplicationForm,
  startupHubChallengeApplicationFormFilesState: HubStartupChallengeApplicationFiles,
  auth: AuthReducer,
  hubChallengeBasicFormFilesState: HubChallengeBasicFormFilesReducer,
  sidebarState: SidebarReducer,
  menuEvaluatorsState: MenuEvaluatorsReducer,
  solutionReducer: solutionReducer,
  evaluatorSolutionState: evaluatorSolutionReducer,
  hubIUserConfigFormState: HubUserConfigFormReducer,
  hubIPasswordState: HubPasswordFormReducer,
  hubIProfileImage: HubProfileImageReducer,
  hotsiteChallengesData: HotsiteChallengesReducer,
  hotsiteIHotsiteRegisterFormState: HotsiteRegisterFormReducer,
  hotsiteIHotsiteEvaluatorRegisterFormState: HotsiteEvaluatorRegisterFormReducer,
  hotsiteIHotsiteContactFormState: HotsiteContactFormReducer,
  hotsiteILandingPageRecommendationFormState: HotsiteRecommendationFormReducer
})

export default reducers
