/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useCallback, useEffect, useState } from 'react'
import { trans } from '../../../../../../../sharedKernel/i18n'
import { ActionButton } from '../style'
import { deleteProvider, postProvider, PostFormat, updateProvider } from '../../../../../../../sharedKernel/api'
import { changeConfigSection, IHubChallengeBasicFormState } from '../store'
import { showToast } from '../../../../../../../components/toast'
import { useNavigate } from 'react-router-dom'
import {
  changeChallengeFiles,
  IFileStructure,
  IHubChallengeBasicFormFilesState
} from '../files/store'
import { toBase64 } from '../../../../../../../sharedKernel/file/handler'
import { ButtonProps } from 'react-bootstrap'
import { AxiosError, AxiosResponse } from 'axios'
import { showDoubleActionPopUp } from 'components/popup/doubleAction'
import { extractData } from 'main/pages/hub/company/challenge/formBuilder/extractData'
import { store } from 'store'
import { ITab } from '../constants'

interface SaveButtonProps extends ButtonProps {
  formStateValues: IHubChallengeBasicFormState
  formFilesState: IHubChallengeBasicFormFilesState
  preRedirectFunction?: React.EventHandler<any>
  launchAction?: boolean
  isLaunched?: boolean
  isActive?: boolean
  text?: string
  tabs: ITab[]
  visible?: boolean
  validateForm: any
  setIsNavigationBlocked: React.EventHandler<any>
  setFilesSaved: React.Dispatch<React.SetStateAction<boolean>>
}

const clearScreenIds = (formStateValues): void => {
  if (formStateValues.id == null) {
    formStateValues.applicationForm.sections = formStateValues.applicationForm.sections.filter(
      (section) => section?.isDeleted !== true
    )
  }

  formStateValues.applicationForm.sections.forEach((section, sectionIndex) => {
    if (section.id?.includes('_idScreen') === true) {
      formStateValues.applicationForm.sections[sectionIndex].id = undefined
    }
    section.fields.forEach((field, fieldIndex) => {
      if (field.id?.includes('_idScreen') === true) {
        formStateValues.applicationForm.sections[sectionIndex].fields[fieldIndex].id = undefined
      }
      field.options?.forEach((option, optionIndex) => {
        if (option.id?.includes('_idScreen') === true) {
          formStateValues.applicationForm.sections[sectionIndex].fields[fieldIndex].options[optionIndex].id = undefined
        }
      })
    })

    if (formStateValues.id == null) {
      section.fields = section.fields.filter((field) => field?.isDeleted !== true)
    }
  })

  formStateValues.evaluation.steps.forEach((step, stepIndex) => {
    if (step.id?.includes('_idScreen') === true) {
      formStateValues.evaluation.steps[stepIndex].id = undefined
    }
    step.sections.forEach((section, sectionIndex) => {
      if (section.id?.includes('_idScreen') === true) {
        formStateValues.evaluation.steps[stepIndex].sections[sectionIndex].id = undefined
      }

      section.fields.forEach((field, fieldIndex) => {
        if (field.id?.includes('_idScreen') === true) {
          formStateValues.evaluation.steps[stepIndex].sections[sectionIndex].fields[fieldIndex].id = undefined
        }
      })
    })
    if (formStateValues.id == null) {
      step.sections.forEach((section) => {
        section.fields = section.fields.filter((field) => field?.isDeleted !== true)
      })
      step.sections = step.sections.filter((section) => section?.isDeleted !== true)
    }
  })
}

const handleHiddenSections = (formStateValues): void => {
  formStateValues.applicationForm.sections.forEach((section) => {
    const nonDeletedFields = section.fields.filter((field) => field?.isDeleted !== true)
    const hiddenFields = nonDeletedFields.filter((field) => field?.isHidden === true)

    if (nonDeletedFields.length === hiddenFields.length) {
      section.isHidden = true
    } else {
      section.isHidden = false
    }
  })
}

export const SaveButton = ({
  formStateValues,
  formFilesState,
  preRedirectFunction,
  setIsNavigationBlocked,
  tabs,
  validateForm,
  launchAction = false,
  isLaunched = false,
  isActive = false,
  text = 'Salvar',
  visible = false,
  setFilesSaved,
  ...props
}: SaveButtonProps): JSX.Element => {
  useEffect(() => {
    for (const key in formStateValues.config.step) {
      // eslint-disable-next-line no-prototype-builtins
      if (formStateValues.config.step.hasOwnProperty(key)) {
        const value = formStateValues.config.step[key]
        if (value === null && launchAction) {
          setIsDisabled(true)
          break
        }
        setIsDisabled(false)
      }
    }
    const { launchDate, applicationStartDate, finalSelectionDate, evaluationStartDate, closingDate } =
      formStateValues.config.step

    if (
      launchDate === null &&
      (applicationStartDate !== null ||
        finalSelectionDate !== null ||
        evaluationStartDate !== null ||
        closingDate !== null)
    ) {
      setIsDisabled(true)
    }
  }, [formStateValues])

  const confirmationMessage = isLaunched
    ? 'Deseja alterar os dados desse evento?'
    : launchAction
      ? 'Deseja publicar esse evento?'
      : 'Deseja salvar esse evento?'

  const [fileName, setFilename] = useState('')
  const [savedChallengeId, setSavedChallengeId] = useState('')

  const onSuccessUploadFile = async (): Promise<void> => {
    showToast('success', labels.uploadFileSuccess.replace('{}', fileName))
  }
  const onErrorUploadFile = async (): Promise<void> => {
    showToast('error', labels.uploadFileFail)
  }

  const redirectToVisualizationScreen = (launched: boolean, challengeId?: string): void => {
    setIsNavigationBlocked(false)
    setTimeout(() => {
      const existingChallengeId = challengeId ?? savedChallengeId
      setFilesSaved(launched)
      navigate(`/hub/company/challenge/${existingChallengeId}`, {
        state: { setToLaunched: launched }
      })
    }, 2500)
  }

  const onSuccessLaunchChallenge = async (): Promise<void> => {
    showToast('success', labels.launchChallengeSuccess)
    const launched = true
    redirectToVisualizationScreen(launched)
  }

  const onErrorLaunchChallenge = async (): Promise<void> => {
    showToast('error', labels.launchChallengeError)
    const launched = false
    redirectToVisualizationScreen(launched)
  }

  const navigate = useNavigate()
  const { mutate: persistChallenge } = postProvider()
  const { mutate: deleteFile } = deleteProvider()
  const { mutate: uploadFile } = postProvider(onSuccessUploadFile, onErrorUploadFile)
  const { mutate: persistLaunchChallenge } = updateProvider(onSuccessLaunchChallenge, onErrorLaunchChallenge)
  const [isDisabled, setIsDisabled] = useState(false)
  const labels = {
    saveChallengeSuccess: trans('Evento salvo com sucesso'),
    FinalSelectionDateError: trans('Data de Encerramento das Inscrições inválida! Eventos já publicados só podem ter datas posteriores a data atual'),
    EvaluationStartDateError: trans('Data Inicial de Avaliação inválida! Eventos já publicados só podem ter datas posteriores a data atual'),
    uploadFileSuccess: trans('O arquivo {} foi salvo com sucesso'),
    uploadFileFail: trans('Falha no upload'),
    launchChallengeSuccess: trans('Parabéns, seu evento foi lançado. Agora é só esperar pelas ideias!'),
    launchChallengeError: trans('Seu evento não foi lançado'),
    invalidForm: trans('Existem dados inválidos no formulário')
  }

  const confirmation = (): any => {
    showDoubleActionPopUp({
      title: text,
      message: confirmationMessage,
      buttons: [{ text: 'Sim' }, { text: 'Não' }],
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      action: async () => handleSubmit()
    })
  }

  const postObject: PostFormat = {
    url: 'v1/hub/company/challenge',
    data: {
      id: formStateValues.id,
      data: formStateValues
    }
  }

  const handleSubmit = useCallback(async (): Promise<void> => {
    extractData(formStateValues, formFilesState, tabs)
    store.dispatch(changeConfigSection(formStateValues))
    store.dispatch(changeChallengeFiles(formFilesState))
    setIsNavigationBlocked(false)
    setIsDisabled(false)
    clearScreenIds(formStateValues)
    handleHiddenSections(formStateValues)
    persistChallenge(postObject, {
      onSuccess: async (response) => handleSavedData(response),
      onError: async (error) => handleSavedDataError(error)
    })
  }, [])

  const handleSavedData = async (response: AxiosResponse): Promise<void> => {
    if (formFilesState.deletedChallengeFiles.length > 0) {
      await deleteUploadedFiles(formFilesState.deletedChallengeFiles, response.data.id)
    }
    await uploadFiles(response.data.id, formFilesState.challengeFiles)

    setIsDisabled(false)

    setSavedChallengeId(response.data.id)

    if (launchAction && response.data.data.isLaunched === false) {
      await handleLaunchChallenge(response.data.id)
    } else {
      showToast('success', labels.saveChallengeSuccess)
      const challengeId = response.data.id
      const launched = response.data.data.isLaunched
      redirectToVisualizationScreen(launched, challengeId)
    }
  }

  const handleSavedDataError = async (error: AxiosError): Promise<void> => {
    setIsDisabled(false)
    const firstError = error.response?.data.errors[0]
    if (firstError.type === 'GreaterThanOrEqualValidator') {
      if (firstError.fieldName === 'ApplicationStartDate' || firstError.fieldName === 'ClosingDate') {
        showToast('error', error.response?.data.errors[0].message)
      }
      if (firstError.fieldName === 'FinalSelectionDate') {
        showToast('error', labels.FinalSelectionDateError)
      }
      if (firstError.fieldName === 'EvaluationStartDate') {
        showToast('error', labels.EvaluationStartDateError)
      }
    }
  }

  const handleLaunchChallenge = async (challengeId: string): Promise<void> => {
    persistLaunchChallenge({ url: `/v1/hub/company/challenge/${challengeId}/launch` })
  }

  const deleteUploadedFiles = async (files: IFileStructure[], challengeId: string): Promise<void> => {
    files.forEach((file) => {
      if (file.id !== undefined && file.id !== null && !file.id.includes('_idScreen')) {
        deleteFile({ url: `/v1/hub/company/challenge/${challengeId}/file/${file.id ?? ''}` })
      }
    })
  }

  const uploadFiles = useCallback(async (challengeId: string, files: IFileStructure[] = []): Promise<void> => {
    const processedFiles = await processUploadedFiles(files.filter((file) => file.id == null))

    processedFiles.forEach((file) => {
      setFilename(atob(file.name))
      uploadFile({
        url: `/v1/hub/company/challenge/${challengeId}/file/upload`,
        data: { data: file }
      })
    })
  }, [])

  const processUploadedFiles = async (files: IFileStructure[]): Promise<IFileStructure[]> => {
    return await Promise.all(
      files.map(async (file): Promise<IFileStructure> => {
        let fileContent, mimetype
        if (file.sourceFile !== undefined) {
          fileContent = await toBase64(file.sourceFile)
          mimetype = file.sourceFile.type
        }

        return {
          mimetype: mimetype,
          name: btoa(unescape(encodeURIComponent(file.name))),
          size: file.size,
          type: file.type,
          content: fileContent
        }
      })
    )
  }

  return (
    <ActionButton
      className="btn btn-primary"
      onClick={() => {
        const isFormValid: boolean = validateForm() ?? false
        if (isFormValid) {
          confirmation()
        }
      }}
      {...props}
    >
      {text}
    </ActionButton>
  )
}
