import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { BaseButton } from 'iebt-zing-storybook'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Tippy from '@tippyjs/react'
import { Loading } from 'components/loading'
import { trans } from 'sharedKernel/i18n'
import { ROUTES } from 'sharedKernel/constants'
import {
  AttachmentsArea,
  AttachmentsItem,
  AttachmentsTitle,
  CoverImage, DescriptionText,
  DescriptionTitle,
  IdeasLimit,
  LinksArea,
  PresentationArea,
  PresentationVideo,
  Title
} from 'sharedKernel/styledComponents/visualizationComponents/styles'
import { Timeline } from 'components/Timeline'
import { isCurrentDateBiggerThanDeadline, isStartDateGreaterThanCurrentDate } from 'sharedKernel/handlers'
import { ChangeDataButtonArea, FormChallengeHeader, FormIdeaSectionName, FormIdeaSections, FormTitle, LoadindContainer, ScreenContainer } from './style'
import { SolutionData, SolutionInfoVisualizationProps } from './contracts'
import { FormAnswer } from 'components/Evaluation/Form/Answer/FormAnswer'
import { api, GetFormat } from 'sharedKernel/api'
import { SwiperNavMenu } from 'components/swiper/swiperNav/swiperNavMenu'

export interface SwiperWrapperProps {
  breakpoints: any
  arrowColor?: string
  sections?: React.ReactNode
}

export interface ICarouselNavItems {
  name: string
  itemHash: string
}

export const SolutionInfoVisualization = ({
  solutionId,
  challengeId
}: SolutionInfoVisualizationProps): JSX.Element => {
  const navigate = useNavigate()
  const [activeSection, setActiveSection] = useState(0)

  const getRequest: GetFormat = {
    url: `/v1/hub/company/solution/${solutionId}`
  }

  const [solutionData, setSolutionData] = useState<SolutionData>()
  useEffect(() => {
    api.get(getRequest.url).then((response) => {
      setSolutionData(response.data)
    })
  }, [])

  const labels = {
    formSection: trans('Formulário de Inscrição'),
    back: trans('Voltar')
  }

  const buildBreakpoints = (): any => {
    return {
      250: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      450: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      700: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      900: {
        slidesPerView: 5,
        spaceBetween: 0
      }
    }
  }

  const SubscriptionFormHash = 'SubscriptionForm'
  const buildNavItems = (): any => {
    return [
      {
        name: labels.formSection,
        itemHash: SubscriptionFormHash
      }
    ]
  }

  if (solutionData == null) {
    return (
        <LoadindContainer>
            <Loading />
        </LoadindContainer>
    )
  }

  function buidSteps(sections: any[]): { formSteps: any[], carouselNavItems: ICarouselNavItems[] } {
    const carouselNavItems: ICarouselNavItems[] = []
    const formSteps: any[] = []

    sections.forEach((section, sectionIndex) => {
      carouselNavItems.push({
        name: section.name ?? '',
        itemHash: section.id
      })
      formSteps.push({
        name: section.id,
        component: (
            <div className='mt-2' key={`${sectionIndex}section`}>
                <div className='d-flex flex-column'>
                        <FormIdeaSectionName>{`${String(section.name)}`}</FormIdeaSectionName>
                        <hr></hr>
                </div>
                {section.answers?.map((answer, answerIndex: string) => {
                  return <FormAnswer key={`${answerIndex}answer`} answer={answer} />
                })}
                </div>
        )
      })
    })

    return {
      carouselNavItems,
      formSteps
    }
  }

  const ideaApplicationData = solutionData
  const solution = ideaApplicationData.data.title
  const sections = ideaApplicationData.data.sections

  const { formSteps } = buidSteps(sections)

  return (
    <>
        <div className='mb-6'>
            <div className="d-flex flex-row">
                <Title>{solution}</Title>
                <ChangeDataButtonArea>
                    <BaseButton
                      btnContent={labels.back}
                      size="big"
                      variant='secondary'
                      onClick={() => navigate(`/hub/company/challenge/${challengeId}/solutions`)}
                    />
                </ChangeDataButtonArea>
            </div>
            <FormChallengeHeader>
                <SwiperNavMenu activeSection={activeSection} setActiveSection={setActiveSection} breakpoints={buildBreakpoints()} carouselNavItems={buildNavItems()} />
            </FormChallengeHeader>
        </div>
        <Row>
            <FormIdeaSections id='FormIdeaSections'>
                {formSteps.map((step, stepIndex) => {
                  return (
                        <section id={step.name} key={stepIndex}>
                            {step.component}
                        </section>
                  )
                })}
            </FormIdeaSections>
        </Row>
    </>
  )
}
