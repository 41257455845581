import React from 'react'
import { HubCompanyChallengeSolutionsManagement } from 'domain/feature/hub/company/challenge/solutions'
import { useParams } from 'react-router'
import { GetFormat, useGet } from 'sharedKernel/api'
import { SolutionInfoVisualization } from 'domain/feature/hub/company/challenge/solutions/info'

export const HubCompanyChallengeSolutionsInfoPage = (): JSX.Element => {
  const { solutionId, challengeId } = useParams()

  return (<SolutionInfoVisualization solutionId={solutionId} challengeId={challengeId} />)
}
