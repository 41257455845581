import styled from 'styled-components'

export interface CoverImageProps {
  backgroundImg?: string
}

export const CoverImage = styled.div<CoverImageProps>`
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 15rem;
  width: 100%;
  border-radius: 0.625rem;
  margin-bottom: 1.875rem;
`

export const IdeasLimit = styled.div`
  border-radius: 4px;
  font-weight: 700;
  opacity: 1;
  font-size: 18px;
  color: var(--primary);
  background-color: var(--white);
  border: 2px solid var(--primary);
  height: fit-content;
  padding: 10px 6px;
  line-height: 16px;
  width: 100%;
  min-width: 6.75rem;
  text-align: center;
`

export const Title = styled.div`
  text-align: justify;
  font-size: 1.75rem;
  font-weight: 600;
`
export const DescriptionTitle = styled.b`
  font-size: 1.375rem;
  color: var(--secondary-i);
`
export const DescriptionText = styled.p`
  white-space: pre-wrap;
  font-size: 1rem;
  text-align: justify;
`

export const PresentationArea = styled.div`
  width: 50vw;
  height: 25vw;
`

export const PresentationVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: fill;
`

export const AttachmentsArea = styled.div`
  line-height: 2rem;
  display: flex;
  flex-direction: column;
`
export const LinksArea = styled.div`
line-height: 2rem;
display: flex;
flex-direction: column;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-size: 0.875rem;
`

export const AttachmentsTitle = styled.b`
  font-size: 1rem;
  font-weight: 600;
  color: var(--secondary-i);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const AttachmentsItem = styled.b`
  font-size: 0.875rem;
  color: var(--primary);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
