import { IMenuEvaluators } from 'components/menu/state/content/store/contracts'
import { Evaluation } from '.'

export const bankersRound = (number: number): number => {
  const value = number * 100
  const roundValue = Math.round(value)
  const bankerRound = value % 1 === 0.5 ? (roundValue % 2 === 0 ? roundValue : roundValue - 1) : roundValue
  const result = bankerRound / 100
  return result
}

export const criterionCount = (evaluation: Evaluation): number => {
  let count = 0
  evaluation.sections.forEach(item => {
    count += Number(item.answers.length)
  })
  return count
}

export const criterionIsMet = (evaluation: Evaluation): number => {
  let count = 0
  evaluation.sections.forEach(item => {
    item.answers.forEach(res => {
      if (res.value !== null) {
        count += 1
      }
    })
  })
  return count
}

export const calculateScore = (evaluation: Evaluation): number => {
  let dividend = 0
  let divisor = 0
  evaluation.sections.forEach(item => {
    item.answers.forEach(res => {
      if (res.value !== null) {
        dividend += (res.value * res.weight)
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        divisor += res.weight
      }
    })
  })

  const division = dividend / divisor
  if (Number.isNaN(division)) return 0
  else return division
}

export const currentSection = (sectionIndex: number, sectionsLength: number): string => {
  const value = `${sectionIndex + 1}/${sectionsLength}`
  return value
}

export const goToSectionTop = (elementId: string): void => {
  const element = document.getElementById(elementId)
  element?.scroll({ top: 0, behavior: 'smooth' })
}

// TO DO: Melhorar essa lógica
export const changeSolutionPoints = (menuEvaluator: IMenuEvaluators, challengeId: string, stepId: string, resolverId: string, ideaId: string, evaluationPoints: string): IMenuEvaluators => {
  menuEvaluator.rows?.forEach((row) => {
    if (row.id === challengeId) {
      row.evaluation.steps.forEach((step) => {
        if (step.id === stepId) {
          step.resolvers.forEach((resolver) => {
            if (resolver.id === resolverId) {
              resolver.solutions.forEach((solution) => {
                if (solution.id === ideaId) {
                  solution.points = Number(evaluationPoints)
                }
              })
            }
          })
        }
      })
    }
  })
  return menuEvaluator
}
