import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { GetFormat, useGet } from 'sharedKernel/api'
import { VisualizationScreen } from 'domain/feature/hub/VisualizationScreen'
import { Loading } from 'components/loading'

export const HubChallengeGetPage = (): JSX.Element => {
  const { challengeId } = useParams()

  const request: GetFormat = {
    url: `v1/hub/company/challenge/${challengeId}`
  }
  const {
    data: challengeData,
    isFetching: isChallengeFetching,
    refetch: challengeRefetch
  } = useGet<any>(request, 'challenge-get')
  const filesRequest: GetFormat = {
    url: `/v1/hub/company/challenge/${challengeId}/files`
  }
  const {
    data: fileData,
    isFetching: isFileFetching,
    refetch: filesRefetch
  } = useGet<any>(filesRequest, 'challenge-files-get')

  useEffect(() => {
    challengeRefetch()
    filesRefetch()
  }, [challengeId])

  const renderResponse = (): JSX.Element => {
    if (isChallengeFetching || isFileFetching) {
      return (
        <Loading />
      )
    }
    return (
      <VisualizationScreen
        isFetching={isChallengeFetching}
        data={challengeData}
        isFileFetching={isFileFetching}
        fileData={fileData?.rows}
      />
    )
  }

  return <>{renderResponse()}</>
}
