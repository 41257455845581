import { BaseButton } from 'iebt-zing-storybook'
import React from 'react'
import { LikertScaleAnswer, LikertScaleRange, LikertScaleWrapper } from './styles'
import { trans } from 'sharedKernel/i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'

interface LikertScaleProps {
  range: number
  answer: number | null
  question: string
  description: string
  gradeInfo?: string
  order: number
  onChange: (value: number | null) => void
  onClick: (value: number | null) => void
}

export const LikertScale = ({
  range,
  answer,
  question,
  description,
  order,
  gradeInfo,
  onChange: handleChange,
  onClick: handleClick
}: LikertScaleProps): JSX.Element => {
  const label = {
    erase: trans('Limpar')
  }

  const btnIcon = (): JSX.Element => {
    return (
      <FontAwesomeIcon
        icon={['fas', 'trash-alt']}
      />
    )
  }
  return (
    <LikertScaleWrapper>
      {`${order + 1}. ${question}`}
      {description !== ''
        ? <Tippy
          content={description}
          placement='right'
          followCursor={true}
          plugins={[followCursor]}
          animation="scale"
          arrow={false}
          theme="primary"
        >
          <span>
            <FontAwesomeIcon
              icon={['fas', 'circle-info']}
              className="ml-2"
            />
          </span>
        </Tippy>
        : <></>
      }
      <LikertScaleAnswer>
      {(gradeInfo !== null && gradeInfo !== '')
        ? <Tippy
          content={gradeInfo}
          placement='left'
          followCursor={true}
          plugins={[followCursor]}
          animation="scale"
          arrow={false}
          theme="primary"
        >
          <span>
            <FontAwesomeIcon
              icon={['fas', 'circle-question']}
            />
          </span>
        </Tippy>
        : <></>
        }
        <LikertScaleRange>
          <input
            list="markers"
            className={answer === null ? 'inactive' : 'active'}
            type="range"
            min="0"
            max={range}
            step="1"
            value={answer === null ? 0 : answer.toString()}
            onChange={(e) => handleChange(parseInt(e.target.value))}
            onClick={(e) => handleClick(parseInt((e.target as HTMLInputElement).value))}
          />
          <datalist id="markers">
            {[...Array(range + 1).keys()].map((value, valueIndex) => {
              return <option key={`likertScaleOption.${valueIndex}`} value={value} />
            })}
          </datalist>
        </LikertScaleRange>
        <div>{answer}</div>
        <Tippy
          content={label.erase}
          placement='top'
          followCursor={true}
          plugins={[followCursor]}
          animation="scale"
          arrow={false}
          theme="primary"
        >
          <div><BaseButton btnContent={btnIcon()} onClick={() => handleChange(null)} variant="primary" /></div>
        </Tippy>
      </LikertScaleAnswer>
    </LikertScaleWrapper>
  )
}
