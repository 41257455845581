import React from 'react'
import { HotsiteFormWrapper, HotsiteFormBox } from './style'

export const HotsiteFormMain = ({ children, formTitle }): JSX.Element => {
  return (
    <HotsiteFormWrapper className='d-flex justify-content-center align-items-center'>
      <HotsiteFormBox className='py-6 px-6 my-6 mx-4 d-flex'>
        {children}
      </HotsiteFormBox>
    </HotsiteFormWrapper>
  )
}
